import { MenuItem, SxProps, Typography } from '@mui/material'
import React, { memo } from 'react'
import { Checkbox } from '../Checkbox/Checkbox'

interface SearchItemProps {
  label: string | React.ReactNode
  selected: boolean
  onClick: () => void
  sx?: SxProps
}

export const SearchItem: React.FC<SearchItemProps> = memo(({ selected, label, onClick, sx }) => {
  return (
    <MenuItem sx={{ pr: '30px', pl: '22px', ...sx }} onClick={onClick}>
      <Checkbox defaultChecked={selected} />
      {typeof label === 'string' ? (
        <Typography sx={{ fontSize: 15, ml: '5px' }} component="span">
          {label}
        </Typography>
      ) : (
        label
      )}
    </MenuItem>
  )
})
