import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../../infrasturcture/theme/styled'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { PenIcon, SaveIcon } from '../../../infrasturcture/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { Search } from '../../../components/Search/Search'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Users } from '../../../types/usersTypes'
import { sectionAccessSchema } from '../../../infrasturcture/yupSchemes/sectionSchema'
import styles from '../../../styles/Form.module.css'
import { rolesRus } from '../../../types/userTypes'
import { OptionInfo } from '../../../components/Options/OptionInfo'

export const SectionForm: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { getSectionThunkCreator, getSectionsThunkCreator, editSectionThunkCreator, getUsersThunkCreator } = useActions()

  const { isLoaded, sections, section } = useTypeSelector((state) => state.sections)
  const { users } = useTypeSelector((state) => state.users)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const optionsUsers = users?.map((u) => ({ label: u.lastname ? `${u.firstname} ${u.lastname}` : u.firstname, value: u }))

  const { handleSubmit, reset, control } = useForm({ resolver: yupResolver(sectionAccessSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    return editSectionThunkCreator({ id, ...data, users: data?.users.map((u: Users) => u.id) }, () => setIsEdit(false), navigate)
  }

  const handleFetch = async (id: string) => {
    !users.length && (await getUsersThunkCreator())
    !sections.length && (await getSectionsThunkCreator())
    getSectionThunkCreator(id, navigate)
  }

  useEffect(() => {
    handleFetch(id!)
  }, [])

  useEffect(() => {
    if (section) {
      reset({ ...section, users: users.filter((u) => section?.users?.includes(u.id)) })
    }
  }, [section])

  return (
    <div className={styles.container}>
      {!isLoaded && section && (
        <>
          <Breadcrumbs customText={section?.name} />
          <StyledHeader>
            <Typography variant="h1">{section?.name}</Typography>
            {isEdit ? (
              <Button onClick={handleSubmit(onSubmit)} color="primary" startIcon={<SaveIcon />}>
                Сохранить
              </Button>
            ) : (
              <IconButton color="inherit" onClick={() => setIsEdit(true)}>
                <PenIcon />
              </IconButton>
            )}
          </StyledHeader>
          <div className={styles.wrapper}>
            <div className={styles.formItem}>
              <Typography variant="h2" color="text.primary">
                Доступы
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Search
                    inputClassName={styles.searchField}
                    control={control}
                    name="users"
                    options={optionsUsers}
                    compareKey="id"
                    placeholder="Введите имя пользователя"
                    emptyMessage="Пользователи отстутствуют"
                    renderOption={(user) => (
                      <div className={styles.option}>
                        <span className={styles.optionInfo}>
                          {user?.lastname} {user?.firstname}
                        </span>
                        <span className={styles.optionSubInfo}>{rolesRus[user?.role]}</span>
                      </div>
                    )}
                    renderSelected={(user, props) => (
                      <OptionInfo
                        {...props}
                        text={`${user?.lastname || ''} ${user?.firstname} ${user?.company ? `(${user?.company})` : ''}`.trim()}
                        subText={rolesRus[user?.role]}
                      />
                    )}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
