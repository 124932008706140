import React, { MouseEvent, useMemo, useState } from 'react'
import { Popper, ClickAwayListener, Typography, Zoom, Box } from '@mui/material'
import { StyledInput, StyledInputContainer } from '../../infrasturcture/theme/styled'
import { FilterButton } from './FilterButton'
import styles from './FilterList.module.css'
import clsx from 'clsx'
import { alphabetSort } from '../../infrasturcture/utils/alphabetSort'

const getItem = <T,>(value: T, searchKey?: keyof T): string => {
  if (typeof value === 'string') return value
  if (searchKey) return String(value[searchKey])
  return ''
}

interface Grouped<T> {
  [key: string]: T[]
}

interface FilterListProps<T> {
  values: T[]
  searchKey?: keyof T
  maxItems?: number
  render: (item: T) => React.ReactNode
}

export const FilterList = <T,>({ values, searchKey, maxItems = 10, render }: FilterListProps<T>) => {
  const [search, setSearch] = useState<string>('')
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'filter-popper' : undefined

  const searchValues = useMemo(() => {
    return values
      ?.filter((item) => getItem(item, searchKey).toLowerCase().includes(search?.toLowerCase()))
      ?.sort((a, b) => alphabetSort(getItem(a, searchKey), getItem(b, searchKey)))
      .reduce<Grouped<T>>((grouped, option) => {
        const key = getItem(option, searchKey).charAt(0).toUpperCase()
        return { ...grouped, [key]: [...(grouped[key] || []), option] }
      }, {})
  }, [search, values])

  const columnCount = Math.min(3, Math.ceil(Object.values(searchValues).flat().length / maxItems))

  return (
    <>
      <FilterButton type="button" aria-describedby={id} onClick={handleClick}>
        Все варианты
      </FilterButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="left"
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 32]
            }
          }
        ]}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        {({ TransitionProps }) => (
          <Zoom {...TransitionProps} timeout={200}>
            <div className={styles.container}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={styles.wrapper}>
                  <div className={styles.header}>
                    <StyledInputContainer sx={{ border: '1px solid #ABABAB', backgroundColor: '#FFFFFF' }}>
                      <StyledInput
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        name="search"
                        placeholder="Поиск"
                        autoComplete="off"
                      />
                    </StyledInputContainer>
                  </div>
                  <div className={styles.columnsContainer}>
                    <div className={clsx(styles.columnsBase, styles[`columns_${columnCount}`])}>
                      <ul className={styles.list}>
                        {searchValues &&
                          Object.entries(searchValues).map(([key, value]) => (
                            <li className={styles.listItem} key={key}>
                              <Typography
                                variant="h3"
                                sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '21px', paddingLeft: '8px', mb: '16px' }}
                              >
                                {key}
                              </Typography>
                              {value.map((item, i) => (
                                <Box key={i}>{render(item)}</Box>
                              ))}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </Zoom>
        )}
      </Popper>
    </>
  )
}
