import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import ProductsApi from '../../api/ProductsApi'
import {
  Product,
  ProductsAction,
  ProductsActionTypes,
  SetProductsAction,
  SetProductLoaded,
  SetProductAction,
  ProductFilters,
  SetProductFiltersAction,
  SetProductsErrorsAction
} from '../../types/productsTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, ProductsAction | SetAuthErrorAction>

const setProductLoaded = (payload: boolean): SetProductLoaded => ({
  type: ProductsActionTypes.SET_LOADED,
  payload
})

export const setProducts = (payload: Product[]): SetProductsAction => ({
  type: ProductsActionTypes.SET_PRODUCTS,
  payload
})

export const setProduct = (payload: Product | null): SetProductAction => ({
  type: ProductsActionTypes.SET_PRODUCT,
  payload
})

export const setProductFilters = (payload: Partial<ProductFilters>): SetProductFiltersAction => ({
  type: ProductsActionTypes.SET_PRODUCT_FILTERS,
  payload
})

export const setProductsErrors = (payload: ErrorMessages): SetProductsErrorsAction => ({
  type: ProductsActionTypes.SET_PRODUCTS_ERRORS,
  payload
})

export const getProductsThunkCreator = (
  limit: number | null,
  page: number | null,
  filters: ProductFilters | null = null
): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setProductLoaded(true))
      const data = await ProductsApi.getProducts(limit, page, null, filters)
      dispatch({ type: ProductsActionTypes.GET_PRODUCTS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getProductThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setProductLoaded(true))
      const data = await ProductsApi.getProduct(id)
      dispatch({ type: ProductsActionTypes.GET_PRODUCT, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const searchProductThunkCreator = (value: string): ThunkType => {
  return async (dispatch) => {
    try {
      const data = value ? await ProductsApi.getProducts(30, 1, value) : []
      dispatch({ type: ProductsActionTypes.SEARCH_PRODUCTS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const createProductThunkCreator = (product: Product, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await ProductsApi.createProduct(product)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setProductsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const editProductThunkCreator = (product: Product, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await ProductsApi.editProduct(product)
      dispatch({ type: ProductsActionTypes.UPDATE_PRODUCT, payload: data })
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setProductsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const deleteProductThunkCreator = (id: string, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await ProductsApi.deleteProduct(id)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setProductsErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}
