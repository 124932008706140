import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { Status } from '../../components/Status/Status'
import { format } from 'date-fns'
import { statusTypes } from '../../types/bookingsTypes'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { OrderForm } from './ui/OrderForm'
import { OrderProducts } from './ui/OrderProducts'
import { createOrderSchema } from '../../infrasturcture/yupSchemes/createOrderSchema'
import { orderStatusMessages } from '../../infrasturcture/utils/statusMessages'
import styles from '../../styles/Operations.module.css'

export const EditOrder: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { order, isLoaded, productsInOrder, errors } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const {
    getOrderThunkCreator,
    getFilterWarehousesThunkCreator,
    editOrderThunkCreator,
    setOrderProducts,
    setOrdersErrors,
    setOrder,
    getProductsInWarehouseThunkCreator
  } = useActions()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const isDisabledProducts = !isEdit || order?.status !== statusTypes.CONFIRMATION

  const methods = useForm({ resolver: yupResolver(createOrderSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const editOrder = {
      ...data,
      ready_at: data.ready_at ? format(data.ready_at, 'yyyy-MM-dd') : null,
      productsInOrder: productsInOrder.map((p) => ({
        product_id: p.id,
        products_audit_id: p.products_audit_id,
        count: p.count,
        checkbox: p.checkbox,
        count_left: p.count_left,
        write_off_left: p.write_off_left
      }))
    }

    return editOrderThunkCreator(
      editOrder,
      () => {
        setOrder({
          ...data,
          created_at: order?.created_at,
          productsInOrder: productsInOrder.map((p) => ({
            ...p,
            count: p.count,
            checkbox: p.checkbox,
            count_left: p.count_left,
            prev_count_left: p.count_left,
            write_off_left: data.status !== statusTypes.SHIPPED_CLOSED ? p.write_off_left : p.write_off_left - p.count_left,
            prev_count: p.count
          }))
        })
        setIsWarning(false)
        setIsEdit(false)
      },
      navigate
    )
  }

  const handleSave = () => {
    if ([statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(methods.getValues('status'))) {
      return setIsWarning(true)
    }
    methods.handleSubmit(onSubmit)()
  }

  useEffect(() => {
    getOrderThunkCreator(id!, navigate)
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
  }, [])

  useEffect(() => {
    if (order) {
      methods.reset({
        ...order,
        full_name: `${order.user_lastname || ''} ${order.user_firstname}`.trim(),
        created_at: format(new Date(order.created_at), 'dd.MM.yyyy  HH:mm'),
        ready_at: order.ready_at ? new Date(order.ready_at) : ''
      })

      setOrderProducts(order.productsInOrder || [])
      getProductsInWarehouseThunkCreator(null)
    }
  }, [order])

  return (
    <div className={styles.container}>
      {!isLoaded && order && (
        <>
          <Breadcrumbs customText={`Заявка №${order.sku}`} />
          <StyledHeader>
            <Typography variant="h1">Заявка №{order.sku}</Typography>
            {![statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(order.status as statusTypes) && (
              <>
                {isEdit ? (
                  <Button onClick={handleSave} color="primary" startIcon={<SaveIcon />}>
                    Сохранить
                  </Button>
                ) : (
                  <>
                    <Status type={order.status} />
                    <IconButton color="inherit" onClick={() => setIsEdit(true)}>
                      <PenIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </StyledHeader>
          <FormProvider {...methods}>
            <OrderForm type="edit" disabled={!isEdit} onSubmit={onSubmit} />
          </FormProvider>
          <OrderProducts type="edit" disabled={isDisabledProducts} />
        </>
      )}
      <SnacbarModal
        onAccept={methods.handleSubmit(onSubmit)}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={orderStatusMessages[methods.getValues('status')]}
      />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setOrdersErrors({})}
        open={!!errors?.productsInOrder}
        message={errors?.productsInOrder}
      />
    </div>
  )
}
