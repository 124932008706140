import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputSearch } from '../../../components/Input/InputSearch'
import { debounce } from '@mui/material'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationsFilter } from '../../../components/Operations/OperationsFilter'
import { OrdersTabs } from './OrdersTabs'
import styles from '../../../styles/Operations.module.css'

export const OrdersContent: React.FC = () => {
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const { managers } = useTypeSelector((state) => state.operations)
  const {
    setOrder,
    setOrders,
    setProducts,
    getProductsThunkCreator,
    getManagersThunkCreator,
    getFilterWarehousesThunkCreator,
    setOrderFilters,
    setOrderProducts
  } = useActions()
  const [query, setQuery] = useState<string>('')

  const debouncedSearch = useCallback(
    debounce((sku: string) => setOrderFilters({ sku }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    setOrder(null)
    setOrders([])
    setOrderProducts([])
    setProducts([])
    getProductsThunkCreator(null, null)
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
    !managers.length && getManagersThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите номер заявки" />
      <OperationsFilter type="orders" />
      <OrdersTabs />
    </div>
  )
}
