import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Button, IconButton, Typography } from '@mui/material'
import { PenIcon, SaveIcon } from '../../infrasturcture/icons'
import { BookingForm } from './ui/BookingForm'
import { BookingProducts } from './ui/BookingProducts'
import { Status } from '../../components/Status/Status'
import { format } from 'date-fns'
import { statusTypes } from '../../types/bookingsTypes'
import { SnacbarModal } from '../../components/MuiComponents/SnacbarModal/SnacbarModal'
import { createOrderSchema } from '../../infrasturcture/yupSchemes/createOrderSchema'
import { bookingStatusMessages } from '../../infrasturcture/utils/statusMessages'
import styles from '../../styles/Operations.module.css'

export const EditBooking: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { booking, isLoaded, productsInBooking, countErrors, products, errors } = useTypeSelector((state) => state.bookings)
  const { productsInOrder } = useTypeSelector((state) => state.orders)
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const {
    getBookingThunkCreator,
    getFilterWarehousesThunkCreator,
    editBookingThunkCreator,
    getBookingsProductsInWarehouseThunkCreator,
    setBookingProducts,
    setOrderProducts,
    addOrderProduct,
    setBookingsCountErrors,
    setBookingsErrors,
    setBooking
  } = useActions()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const isDisabledProducts = !isEdit || booking?.status !== statusTypes.CONFIRMATION

  const methods = useForm({ resolver: yupResolver(createOrderSchema), mode: 'onChange' })

  const onSubmit = (data: any) => {
    const editBooking = {
      ...data,
      ready_at: data.ready_at ? format(data.ready_at, 'yyyy-MM-dd') : null,
      productsInBooking: productsInBooking.map((p) => ({
        product_id: p.id,
        products_audit_id: p.products_audit_id,
        count: p.count,
        write_off_left: p.write_off_left
      })),
      productsInOrder: productsInOrder.map((p) => ({
        product_id: p.id,
        products_audit_id: p.products_audit_id,
        count: p.count,
        checkbox: p.checkbox,
        count_left: p.count_left,
        write_off_left: p.write_off_left
      }))
    }

    return editBookingThunkCreator(
      editBooking,
      () => {
        setBooking({
          ...data,
          created_at: booking?.created_at,
          productsInBooking: productsInBooking.map((p) => ({
            ...p,
            count: p.count,
            write_off_left: data.status !== statusTypes.SHIPPED_CLOSED ? p.write_off_left : 0,
            prev_count: p.count
          })),
          productsInOrder: productsInOrder.map((p) => ({
            ...p,
            count: p.count,
            checkbox: p.checkbox,
            count_left: p.count_left,
            prev_count_left: p.count_left,
            write_off_left: data.status !== statusTypes.SHIPPED_CLOSED ? p.write_off_left : p.write_off_left - p.count_left,
            prev_count: p.count
          }))
        })
        setIsWarning(false)
        setIsEdit(false)
      },
      navigate
    )
  }

  const handleSave = () => {
    if ([statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(methods.getValues('status'))) {
      return setIsWarning(true)
    }
    methods.handleSubmit(onSubmit)()
  }

  const handleAddOrderProduct = () => {
    const product = products.find((p) => p.id === countErrors?.id)!
    const isExistOrderProduct = productsInOrder.find((p) => p.id === countErrors?.id)
    !isExistOrderProduct && addOrderProduct({ ...product, count: 1, count_left: 1, write_off_left: 1 })
    setBookingsCountErrors(null)
  }

  useEffect(() => {
    getBookingThunkCreator(id!, navigate)
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
  }, [])

  useEffect(() => {
    if (booking) {
      methods.reset({
        ...booking,
        full_name: `${booking.user_lastname || ''} ${booking.user_firstname}`.trim(),
        created_at: format(new Date(booking.created_at), 'dd.MM.yyyy  HH:mm'),
        ready_at: booking.ready_at ? new Date(booking.ready_at) : ''
      })

      setBookingProducts(booking.productsInBooking || [])
      setOrderProducts(booking.productsInOrder || [])

      getBookingsProductsInWarehouseThunkCreator(booking.warehouse_id)
    }
  }, [booking])

  return (
    <div className={styles.container}>
      {!isLoaded && booking && (
        <>
          <Breadcrumbs customText={`Бронирование №${booking.sku}`} />
          <StyledHeader>
            <Typography variant="h1">Бронирование №{booking.sku}</Typography>
            {![statusTypes.CANCELED, statusTypes.SHIPPED_CLOSED].includes(booking.status as statusTypes) && (
              <>
                {isEdit ? (
                  <Button onClick={handleSave} color="primary" startIcon={<SaveIcon />}>
                    Сохранить
                  </Button>
                ) : (
                  <IconButton color="inherit" sx={{ order: 3 }} onClick={() => setIsEdit(true)}>
                    <PenIcon />
                  </IconButton>
                )}
              </>
            )}
            {!isEdit && <Status type={booking.status} />}
          </StyledHeader>
          <FormProvider {...methods}>
            <BookingForm type="edit" disabled={!isEdit} onSubmit={onSubmit} />
          </FormProvider>
          <BookingProducts type="edit" disabled={isDisabledProducts} />
        </>
      )}
      <SnacbarModal
        onAccept={handleAddOrderProduct}
        onCancel={() => setBookingsCountErrors(null)}
        open={!!countErrors}
        message={countErrors?.message}
      />
      <SnacbarModal
        onAccept={methods.handleSubmit(onSubmit)}
        onCancel={() => setIsWarning(false)}
        open={isWarning}
        message={bookingStatusMessages[methods.getValues('status')]}
      />
      <SnacbarModal
        isError
        isActions={false}
        onCancel={() => setBookingsErrors({})}
        open={!!errors?.productsInBooking}
        message={errors?.productsInBooking}
      />
    </div>
  )
}
