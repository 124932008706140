import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { MuiTabPanel } from './MuiTabPanel'

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface TabItem {
  label: React.ReactNode
  content?: React.ReactNode | string | number
  onClick?: () => void
}

interface MuiTabsProps {
  tabs: TabItem[]
  className?: string
  index?: number
}

export const MuiTabs: React.FC<MuiTabsProps> = ({ tabs, className, index = 0 }) => {
  const [value, setValue] = React.useState(index)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className={className}>
      <Box sx={{ borderBottom: 1, borderColor: '#ABABAB33' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map(({ label, onClick }, i) => (
            <Tab
              key={i}
              label={label}
              onClick={onClick}
              {...a11yProps(i)}
              sx={{
                padding: '12px 20px',
                borderRadius: '8px 8px 0 0',
                fontWeight: 400,
                letterSpacing: 0,
                '&.Mui-selected': { fontWeight: 500 }
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ content }, i) => (
        <MuiTabPanel key={i} value={value} index={i}>
          {content}
        </MuiTabPanel>
      ))}
    </Box>
  )
}
