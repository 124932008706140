import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputSearch } from '../../../components/Input/InputSearch'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useActions } from '../../../hooks/useActions'
import { debounce } from '@mui/material'
import { OperationsFilter } from '../../../components/Operations/OperationsFilter'
import { OperationsTabs } from './OperationsTabs'
import styles from '../../../styles/Operations.module.css'

export const OperationsContent: React.FC = () => {
  const {
    getWarehousesThunkCreator,
    getProductsThunkCreator,
    getManagersThunkCreator,
    setOperations,
    setOperationFilters,
    setOperationFormValues,
    setOperationProducts,
    setOrdersPostingProducts,
    setOrdersPosting,
    setBookingsDone,
    setBookingsDoneProducts,
    cleanOperationSearchProducts,
    setProducts
  } = useActions()
  const { managers } = useTypeSelector((state) => state.operations)
  const { warehouses } = useTypeSelector((state) => state.warehouses)
  const [query, setQuery] = useState<string>('')

  const debouncedSearch = useCallback(
    debounce((sku: string) => setOperationFilters({ sku }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    setOperations([])
    setOperationFormValues({})
    setOperationProducts([])
    cleanOperationSearchProducts([])
    setOrdersPosting([])
    setOrdersPostingProducts([])
    setBookingsDone([])
    setBookingsDoneProducts([])
    setProducts([])
    getProductsThunkCreator(null, null)
    !warehouses.length && getWarehousesThunkCreator({})
    !managers.length && getManagersThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите номер операциии" />
      <OperationsFilter type="operations" />
      <OperationsTabs />
    </div>
  )
}
