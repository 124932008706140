import React from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { Input } from '../../../components/Input/Input'
import { Select } from '../../../components/Select/Select'
import { InputPassword } from '../../../components/Input/InputPassword'
import { PhoneInput } from '../../../components/PhoneInput/PhoneInput'
import { Search } from '../../../components/Search/Search'
import { roles } from '../../../types/userTypes'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import styles from '../../../styles/Form.module.css'

const options: Array<{ label: string; value: string }> = [
  { value: roles.ADMINISTRATOR, label: 'Администратор' },
  { value: roles.MANAGER, label: 'Менеджер' },
  { value: roles.DEALER, label: 'Дилер' },
  { value: roles.DRIVER, label: 'Водитель' }
]

interface UserFormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  disabled?: boolean
}

export const UserForm: React.FC<UserFormProps> = ({ onSubmit, disabled = false, type }) => {
  const { setUsersErrors } = useActions()
  const { errors: stateErrors } = useTypeSelector((state) => state.users)
  const { sections } = useTypeSelector((state) => state.sections)
  const { warehouses } = useTypeSelector((state) => state.warehouses)

  const isDisabled = type === 'edit' && disabled
  const optionsSections = sections?.map((s) => ({ label: s.name, value: s }))
  const optionsWarehouses = warehouses?.map((w) => ({ label: w.name, value: w }))

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Общая информация
          </Typography>
          <Grid className={styles.fields} container spacing={3}>
            <Grid item xs={6}>
              <Input
                control={control}
                label={errors?.firstname?.message ?? 'Имя'}
                placeholder="Имя"
                name="firstname"
                error={!!errors?.firstname?.message}
                disabled={isDisabled}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                control={control}
                options={options}
                label={errors?.role?.message ?? 'Роль'}
                name="role"
                error={!!errors?.role?.message}
                disabled={isDisabled}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                control={control}
                label={errors?.lastname?.message ?? 'Фамилия'}
                placeholder="Фамилия"
                name="lastname"
                error={!!errors?.lastname?.message}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <Input control={control} label="Компания" placeholder="Компания" name="company" disabled={isDisabled} />
            </Grid>
          </Grid>
        </div>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Личные данные
          </Typography>
          <Grid className={styles.fields} container spacing={3}>
            <Grid item xs={6}>
              <Input
                onChange={() => {
                  const { email, ...errors } = stateErrors
                  setUsersErrors(errors)
                }}
                type="email"
                control={control}
                label={(stateErrors?.email || errors?.email?.message) ?? 'Почта'}
                placeholder="Почта"
                name="email"
                error={!!stateErrors?.email || !!errors?.email?.message}
                required
                disabled={isDisabled}
              />
            </Grid>
            {type === 'create' && (
              <Grid item xs={6}>
                <InputPassword
                  control={control}
                  onChange={() => trigger('repeatPassword')}
                  name="password"
                  label={errors?.password?.message ?? 'Пароль'}
                  placeholder="Пароль"
                  error={!!errors?.password?.message}
                  startIcon={false}
                  required
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <PhoneInput
                control={control}
                label={(stateErrors?.phone || errors?.phone?.message) ?? 'Телефон'}
                placeholder="Телефон"
                name="phone"
                error={!!stateErrors?.phone || !!errors?.phone?.message}
                disabled={isDisabled}
              />
            </Grid>
            {type === 'create' && (
              <Grid item xs={6}>
                <InputPassword
                  control={control}
                  name="repeatPassword"
                  label={errors?.repeatPassword?.message ?? 'Повторите пароль'}
                  placeholder="Повторите пароль"
                  error={!!errors?.repeatPassword?.message}
                  startIcon={false}
                  required
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary" sx={{ mb: '20px' }}>
            Доступы
          </Typography>
          <Grid container rowGap={5}>
            <Grid container columnSpacing={3} item xs={12}>
              <Grid item xs={10}>
                <Search
                  inputClassName={styles.searchField}
                  control={control}
                  name="sections"
                  options={optionsSections}
                  compareKey="id"
                  displayKey="name"
                  label="Разделы"
                  placeholder="Введите название раздела"
                  emptyMessage="Доступы к разделам отстутствуют"
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} item xs={12}>
              <Grid item xs={10}>
                <Search
                  inputClassName={styles.searchField}
                  control={control}
                  name="warehouses"
                  options={optionsWarehouses}
                  compareKey="id"
                  displayKey="name"
                  label="Склады"
                  placeholder="Введите название склада"
                  emptyMessage="Доступы к складам отстутствуют"
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  )
}
