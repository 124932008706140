import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { useActions } from '../../hooks/useActions'
import { changePasswordSchema } from '../../infrasturcture/yupSchemes/changePasswordSchema'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { Button, Grid, Typography } from '@mui/material'
import { SaveIcon } from '../../infrasturcture/icons'
import { Input } from '../../components/Input/Input'
import { InputPassword } from '../../components/Input/InputPassword'
import { useNavigate } from 'react-router-dom'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import styles from '../../styles/Form.module.css'

interface ChangePasswordFields {
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate()
  const { changePasswordThunkCreator } = useActions()
  const { errors: stateErrors } = useTypeSelector((state) => state.user)

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm<ChangePasswordFields>({ resolver: yupResolver(changePasswordSchema), mode: 'onChange' })

  const onSubmit = (data: ChangePasswordFields) => {
    const { oldPassword, newPassword } = data
    return changePasswordThunkCreator(oldPassword, newPassword, () => navigate('/profile'), navigate)
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader>
        <Typography variant="h1">Изменение пароля</Typography>
        <Button onClick={handleSubmit(onSubmit)} color="primary" startIcon={<SaveIcon />}>
          Сохранить
        </Button>
      </StyledHeader>
      <div className={styles.wrapperSmall}>
        <div className={styles.formItem}>
          <Typography variant="h2" color="text.primary">
            Заполните данные
          </Typography>
          <Grid className={styles.fields} container spacing={3} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <Input
                control={control}
                label={(stateErrors?.oldPassword || errors?.oldPassword?.message) ?? 'Старый пароль'}
                placeholder="Старый пароль"
                name="oldPassword"
                error={!!stateErrors?.oldPassword || !!errors?.oldPassword?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputPassword
                control={control}
                onChange={() => trigger('repeatPassword')}
                name="newPassword"
                label={errors?.newPassword?.message ?? 'Новый пароль'}
                placeholder="Новый пароль"
                error={!!errors?.newPassword?.message}
                startIcon={false}
              />
            </Grid>
            <Grid item xs={12}>
              <InputPassword
                control={control}
                name="repeatPassword"
                label={errors?.repeatPassword?.message ?? 'Повторите пароль'}
                placeholder="Повторите пароль"
                error={!!errors?.repeatPassword?.message}
                startIcon={false}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
