import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { debounce } from '@mui/material'
import { InputSearch } from '../../../components/Input/InputSearch'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { useBalance } from '../../../hooks/useBalance'
import clsx from 'clsx'
import { BalancesFilter } from '../../../components/Balances/BalancesFilter'
import { Loader } from '../../../components/Loader/Loader'
import { EmptyMessage } from '../../../components/EmptyMessage/EmptyMessage'
import styles from '../../../styles/Balances.module.css'

export const SupplierContent: React.FC = () => {
  const { setSupplierBalances, getSupplierBalancesThunkCreator, getManufacturersThunkCreator, setSupplierFilters } = useActions()
  const { supplierBalances, isLoaded, supplierFilters } = useTypeSelector((state) => state.balances)
  const { manufacturers } = useTypeSelector((state) => state.manufacturers)
  const {
    sortedWarehouses,
    dragStartHandler,
    dragLeaveHandler,
    dragEndHandler,
    dragOverHandler,
    dragDropHandler,
    CELL_WIDTH_STYLE
  } = useBalance()

  const [query, setQuery] = useState<string>('')

  const debouncedSearch = useCallback(
    debounce((search: string) => setSupplierFilters({ search }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    setSupplierBalances([])
    !manufacturers.length && getManufacturersThunkCreator()
  }, [])

  useEffect(() => {
    getSupplierBalancesThunkCreator(supplierFilters)
  }, [supplierFilters])

  return (
    <div className={styles.container}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите название или код товара" />
      <BalancesFilter type="supplier" />
      <div className={clsx(styles.tableContainer, styles.withoutTabs)}>
        {!!sortedWarehouses.length && !!supplierBalances.length ? (
          <>
            <table className={styles.tableHeader}>
              <thead className={styles.thead}>
                <tr className={styles.row}>
                  <td className={clsx(styles.cell, styles.productCell, styles.theadFirstCellSticky)}>Товар</td>
                  <td className={clsx(styles.cell, styles.balanceCell, styles.theadSecondCellSticky)} style={CELL_WIDTH_STYLE}>
                    Итог
                  </td>
                  {sortedWarehouses.map((item) => (
                    <td
                      key={item.id}
                      className={clsx(styles.cell, styles.cursorMove, styles.warehouseCell)}
                      style={CELL_WIDTH_STYLE}
                      draggable={true}
                      onDragStart={(e) => dragStartHandler(e, item)}
                      onDragLeave={(e) => dragLeaveHandler(e)}
                      onDragEnd={(e) => dragEndHandler(e, item)}
                      onDragOver={(e) => dragOverHandler(e)}
                      onDrop={(e) => dragDropHandler(e, item)}
                    >
                      {item.name}
                    </td>
                  ))}
                </tr>
              </thead>
            </table>
            <table className={styles.tableBody}>
              <tbody className={styles.emptyBody}></tbody>
              <tbody className={styles.tbody}>
                {supplierBalances.map((item) => (
                  <tr key={item.id} className={styles.row}>
                    <td className={clsx(styles.cell, styles.productCell, styles.tbodyFirstCellSticky)}>
                      <ProductInfo product={item} clamp={false} />
                    </td>
                    <td className={clsx(styles.cell, styles.balanceCell, styles.tbodySecondCellSticky)} style={CELL_WIDTH_STYLE}>
                      {item.totalCount}
                    </td>
                    {sortedWarehouses.map(({ id }) => (
                      <td key={id} className={clsx(styles.cell, styles.warehouseCell)} style={CELL_WIDTH_STYLE}>
                        {item.warehouses.find((warehouse) => warehouse.warehouse_id === id)?.count || 0}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : isLoaded ? (
          <Loader className={styles.loader} />
        ) : (
          <EmptyMessage className={styles.emptyData}>Данные об поставках отсутствуют</EmptyMessage>
        )}
      </div>
    </div>
  )
}
