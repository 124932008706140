import React, { useEffect, useRef, useState } from 'react'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { Operation, operationTypesRus } from '../../../types/operationsTypes'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { StyledCell } from '../../../infrasturcture/theme/styled'
import { BitrixLink } from '../../../components/BitrixLink/BitrixLink'
import { formatDate } from '../../../infrasturcture/utils/formatDate'
import { useNavigate } from 'react-router-dom'
import { useDoubleClick } from '../../../hooks/useDoubleClick'
import { MuiTabs } from '../../../components/MuiComponents/MuiTabs/MuiTabs'
import { OperationsTab } from '../../../components/Operations/OperationsTab'
import { OperationComment } from '../../../components/Operations/OperationComment'
import { useActions } from '../../../hooks/useActions'
import { OperationRow } from './OperationRow'
import { useObserver } from '../../../hooks/useObserver'
import { Option } from '../../../components/Options/Option'
import styles from '../../../styles/Operations.module.css'

const operationsColumns: VgTableColumnType<Operation>[] = [
  {
    key: 'sku',
    title: 'Номер',
    width: '12%'
  },
  {
    key: 'type',
    title: 'Тип',
    render: (_, { type }) => operationTypesRus[type],
    width: '15%'
  },
  {
    key: 'user_firstname',
    title: 'Пользователь',
    width: '15%',
    render: (_, item) => (
      <Option
        text={`${item.user_lastname || ''} ${item.user_firstname}`.trim()}
        subText={item.user_company}
        fontWeight={500}
        variant="table"
      />
    )
  },
  {
    key: 'created_at',
    title: 'Дата создания',
    render: (_, item) => <StyledCell>{formatDate(item.created_at)}</StyledCell>,
    width: '13%'
  },
  {
    key: 'warehouse_name_from',
    title: 'Склад',
    width: '15%'
  },
  {
    key: 'bitrix_id',
    title: 'Id B24',
    render: (_, item) => <BitrixLink bitrixId={item.bitrix_id} />,
    width: '10%'
  },
  {
    key: 'comment',
    title: 'Комментарий',
    render: (_, { comment }) => <OperationComment>{comment}</OperationComment>,
    width: '20%'
  }
]

export const OperationsTabs: React.FC = () => {
  const navigate = useNavigate()
  const { getOperationThunkCreator, getOperationsThunkCreator, setOperation, setOperations, setOperationFilters } = useActions()
  const { operations, operation, count, countQuickFilter, limit, isLoaded, filters } = useTypeSelector(
    (state) => state.operations
  )
  const [page, setPage] = useState<number>(1)

  const isFiltered = useRef<boolean>(false)
  const totalPages = Math.ceil((filters.quickFilter ? countQuickFilter : count) / limit)
  const lastElement = useRef<HTMLDivElement | null>(null)

  const handleClick = useDoubleClick(
    (id: string) => {
      if (id === String(operation?.sku)) return setOperation(null)
      getOperationThunkCreator(id, navigate)
    },
    (id: string) => navigate(`/operations/${id}`)
  )

  const tabs = [
    {
      onClick: () => setOperationFilters({ ...filters, quickFilter: false }),
      label: (
        <OperationsTab count={count} variant="orange">
          Все операции
        </OperationsTab>
      ),
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={operationsColumns}
          data={operations}
          renderCollapsibleRow={(o) => (o.id === operation?.id ? <OperationRow /> : undefined)}
        />
      )
    },
    {
      onClick: () => setOperationFilters({ ...filters, quickFilter: true }),
      label: <OperationsTab count={countQuickFilter}>Оприходования под заявку</OperationsTab>,
      content: (
        <VgTable
          onRowClick={(o) => handleClick(String(o.sku))}
          columns={operationsColumns}
          data={operations}
          renderCollapsibleRow={(o) => (o.id === operation?.id ? <OperationRow /> : undefined)}
        />
      )
    }
  ]

  useEffect(() => {
    isFiltered.current = true
    setOperations([])
    setPage(1)
  }, [filters])

  useEffect(() => {
    if (isFiltered.current && page > 1) return
    getOperationsThunkCreator(limit, page, filters)
    isFiltered.current = false
  }, [page, filters])

  useObserver(lastElement, '100px', isLoaded, page < totalPages, () => {
    if (!isFiltered.current) setPage(page + 1)
  })

  return (
    <>
      <MuiTabs className={styles.tabs} tabs={tabs} />
      <div style={{ padding: '1px' }} ref={lastElement}></div>
    </>
  )
}
