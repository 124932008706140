import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs'
import { StyledHeader } from '../../infrasturcture/theme/styled'
import { Typography } from '@mui/material'
import { SupplierContent } from './ui/SupplierContent'
import styles from '../../styles/Balances.module.css'

export const Supplier: React.FC = () => {
  return (
    <div className={styles.container}>
      <Breadcrumbs />
      <StyledHeader sx={{ mb: '40px' }}>
        <Typography variant="h1">Поставщикам</Typography>
      </StyledHeader>
      <SupplierContent />
    </div>
  )
}
