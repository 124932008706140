import React, { useMemo, useRef, useState } from 'react'
import { Warehouse } from '../types/warehousesTypes'
import { useActions } from './useActions'
import { useMediaQuery } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTypeSelector } from './useTypeSelector'

export const useBalance = () => {
  const { setBalancesWarehouses, updateWarehouseOrderThunkCreator } = useActions()
  const { warehouses } = useTypeSelector((state) => state.balances)

  const [currentWarehouse, setCurrentWarehouse] = useState<Warehouse | null>(null)

  const matches = useMediaQuery('(max-width: 900px)')

  const lastElement = useRef<HTMLDivElement | null>(null)
  const CELL_WIDTH_STYLE: React.CSSProperties = warehouses.length <= 3 ? { maxWidth: 135, minWidth: 135 } : {}
  const isFiltered = useRef<boolean>(false)

  const sortedWarehouses = useMemo(
    () => warehouses.sort((a, b) => (a.warehouse_order > b.warehouse_order ? 1 : -1)),
    [warehouses]
  )

  const methods = useForm({ defaultValues: { date: new Date() } })

  const dragStartHandler = (e: any, th: Warehouse) => {
    setCurrentWarehouse(th)
  }

  const dragLeaveHandler = (e: any) => {
    e.target.style.boxShadow = 'none'
  }

  const dragEndHandler = (e: any, th: Warehouse) => {
    e.target.style.boxShadow = 'none'
    updateWarehouseOrderThunkCreator({
      id: th.id,
      old: currentWarehouse!.warehouse_order,
      new: th.warehouse_order
    })
  }

  const dragOverHandler = (e: any) => {
    e.preventDefault()
    e.target.style.boxShadow = '3px 0px 3px rgba(0, 0, 0, 0.3)'
  }

  const dragDropHandler = (e: any, th: Warehouse) => {
    e.preventDefault()

    const newWarehouses =
      currentWarehouse!.warehouse_order > th.warehouse_order
        ? warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order < currentWarehouse!.warehouse_order &&
              item.warehouse_order >= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order + 1 }
            }
            return item
          })
        : warehouses.map((item) => {
            if (item.id === currentWarehouse?.id) {
              return { ...item, warehouse_order: th.warehouse_order }
            }
            if (
              item.id !== currentWarehouse?.id &&
              item.warehouse_order > currentWarehouse!.warehouse_order &&
              item.warehouse_order <= th.warehouse_order
            ) {
              return { ...item, warehouse_order: item.warehouse_order - 1 }
            }
            return item
          })

    setBalancesWarehouses(newWarehouses)

    e.target.style.boxShadow = 'none'
  }

  const value = useMemo(
    () => ({
      matches,
      dragStartHandler,
      dragLeaveHandler,
      dragEndHandler,
      dragOverHandler,
      dragDropHandler,
      CELL_WIDTH_STYLE,
      lastElement,
      methods,
      isFiltered,
      sortedWarehouses
    }),
    [matches, CELL_WIDTH_STYLE, lastElement, methods, isFiltered, sortedWarehouses]
  )

  return value
}
