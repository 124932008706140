import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { Input } from '../../../components/Input/Input'
import { Textarea } from '../../../components/Input/Textarea'
import formStyles from '../../../styles/Form.module.css'
import { Select } from '../../../components/Select/Select'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { operationTypes, purposeTypesRus } from '../../../types/operationsTypes'
import { OperationSearch } from '../../../components/Operations/OperationSearch'
import { PlusIcon } from '../../../infrasturcture/icons'
import { Product } from '../../../types/productsTypes'
import { useActions } from '../../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import { ProductInfo } from '../../../components/Products/ProductInfo'
import { SearchInput } from '../../../components/SearchInput/SearchInput'

interface OperationText {
  [key: string]: { buttonText: string; buttonHref: string; helpText: string }
}

const getOperationText = (type: string) => {
  const config: OperationText = {
    POSTING: {
      buttonText: 'Добавить заявки для оприходования',
      buttonHref: `/operations/add/${type.toLowerCase()}/orders_posting`,
      helpText: '* При оприходовании всех товаров в заявке, статус заявки меняется на ‘Готово’.'
    },
    WRITE_OFF: {
      buttonText: 'Добавить бронирования для списания',
      buttonHref: '/operations/add/write_off/bookings_done',
      helpText: `* При списании всех товаров в бронировании, статус бронирования меняется на ‘Отгружено и закрыто’. 
      При частичном списании товаров в бронировании, статус бронирования меняется на ‘Частично отгружен’.`
    },
    MOVING: {
      buttonText: 'Добавить заявки для оприходования',
      buttonHref: `/operations/add/${type.toLowerCase()}/orders_posting`,
      helpText: '* При перемещении всех товаров под заявку, статус заявки меняется на  `Готово`.'
    }
  }

  return config[type]
}

const purposeOptions = Object.entries(purposeTypesRus).map(([value, label]) => ({ label, value }))

interface OperationFormProps {
  onSubmit: SubmitHandler<any>
  type: 'create' | 'edit'
  operationType: string
  disabled?: boolean
}

export const OperationForm: React.FC<OperationFormProps> = ({ onSubmit, disabled, type, operationType }) => {
  const navigate = useNavigate()
  const {
    addProduct,
    getOrdersPostingThunkCreator,
    getProductsInWarehouseThunkCreator,
    getBookingsDoneThunkCreator,
    setOperationFormValues,
    setOperationsErrors,
    setOperationProducts,
    setOrdersPostingProducts,
    cleanOperationSearchProducts,
    getBookingsProductsInWarehouseThunkCreator
  } = useActions()
  const { products, productsInOperation, errors: stateErrors } = useTypeSelector((state) => state.operations)
  const { ordersPosting } = useTypeSelector((state) => state.orders)
  const { bookingsDone } = useTypeSelector((state) => state.bookings)
  const { warehouses: stateWarehouses } = useTypeSelector((state) => state.warehouses)

  const isDisabled = type === 'edit' && disabled
  const operationText = getOperationText(operationType)
  const isMoving = operationType === operationTypes.MOVING

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const warehouseIdFrom = watch('warehouse_id_from')
  const warehouseIdTo = watch('warehouse_id_to')

  const warehousesFrom = isMoving ? stateWarehouses.filter((w) => w.id !== warehouseIdTo) : stateWarehouses
  const warehousesTo = isMoving ? stateWarehouses.filter((w) => w.id !== warehouseIdFrom) : []

  const handleAddProduct = (product: Product) => {
    const isExistProduct = productsInOperation.some((item) => item.id === product.id)

    if (!isExistProduct) addProduct({ ...product, count: 1 })

    if (operationType === operationTypes.WRITE_OFF) {
      const isExistBookingDoneProduct = bookingsDone
        .flatMap((b) => b.productsInBooking.concat(b.productsInOrder))
        .some((p) => p.id === product.id)

      if (isExistBookingDoneProduct) {
        type === 'create' && setOperationFormValues(watch())
        navigate(`/operations/add/write_off/bookings_done?productId=${product.id}`)
      }
    }

    if ([operationTypes.POSTING, operationTypes.MOVING].includes(operationType as operationTypes)) {
      const isExistPostingProduct = ordersPosting.flatMap((o) => o.productsInOrder).some((p) => p.id === product.id)

      if (isExistPostingProduct) {
        type === 'create' && setOperationFormValues(watch())
        navigate(`/operations/add/${operationType.toLowerCase()}/orders_posting?productId=${product.id}`)
      }
    }

    setOperationsErrors({})
  }

  const handleWarehouseFromChange = (warehouseId: string | null) => {
    setValue('warehouse_id_from', warehouseId, { shouldValidate: true })
    cleanOperationSearchProducts([])
    setOperationProducts([])
    setOrdersPostingProducts([])
    if (!warehouseId) return

    if (operationType === operationTypes.POSTING) {
      getOrdersPostingThunkCreator(warehouseId)
      return getProductsInWarehouseThunkCreator(null)
    }
    if (operationType === operationTypes.WRITE_OFF) {
      const bookingWarehouseId = stateWarehouses.find((w) => w.id === warehouseId)?.booking_warehouse_id
      bookingWarehouseId && getBookingsProductsInWarehouseThunkCreator(bookingWarehouseId)
      getBookingsDoneThunkCreator(warehouseId)
    }
    getProductsInWarehouseThunkCreator(warehouseId)
  }

  const handleWarehouseToChange = (warehouseId: string | null) => {
    setValue('warehouse_id_to', warehouseId, { shouldValidate: true })
    setOperationProducts([])
    setOrdersPostingProducts([])
    if (warehouseId) getOrdersPostingThunkCreator(warehouseId)
  }

  const handleFocus = () => {
    if (!watch('warehouse_id_from')) {
      setError('warehouse_id_from', { message: 'Укажите склад' })
    }
    if (isMoving && !watch('warehouse_id_to')) {
      setError('warehouse_id_to', { message: 'Укажите второй склад' })
    }
  }

  const handleFormSave = () => {
    if (type === 'create') {
      setOperationFormValues(watch())
    }
  }

  return (
    <div className={formStyles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={formStyles.formItem}>
          <Typography variant="h2" color="text.primary">
            {type === 'create' ? 'Данные об операции' : 'Общая информация'}
          </Typography>
          <Grid className={formStyles.fields} container spacing={3}>
            <Grid container item xs={6} spacing={3}>
              <Grid item xs={12}>
                <SearchInput
                  onChange={handleWarehouseFromChange}
                  value={warehouseIdFrom}
                  options={warehousesFrom.map((w) => ({ label: w.name, value: w.id }))}
                  label={
                    (stateErrors?.productsInOperation || errors?.warehouse_id_from?.message) ?? (isMoving ? 'Со склада' : 'Склад')
                  }
                  placeholder={isMoving ? 'Со склада' : 'Склад'}
                  error={!!stateErrors?.productsInOperation || !!errors?.warehouse_id_from?.message}
                  disabled={type === 'edit'}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  control={control}
                  options={[{ value: '', label: '<Не выбрано>' }, ...purposeOptions]}
                  label="Тип операции"
                  name="purpose"
                  disabled={isDisabled}
                />
              </Grid>
              {type === 'edit' && (
                <>
                  <Grid item xs={12}>
                    <Input control={control} label="Менеджер" placeholder="Менеджер" name="full_name" disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <Input control={control} label="Дата создания" placeholder="Дата создания" name="created_at" disabled />
                  </Grid>
                </>
              )}
              {type === 'create' && (
                <Grid item xs={12}>
                  <Input control={control} label="Id B24" placeholder="Id B24" name="bitrix_id" disabled={isDisabled} />
                </Grid>
              )}
            </Grid>
            <Grid container item xs={6} spacing={3}>
              {type === 'edit' && (
                <Grid item xs={12} order={isMoving ? 2 : undefined}>
                  <Input
                    control={control}
                    label="Id B24"
                    placeholder="Id B24"
                    name="bitrix_id"
                    disabled={type === 'edit' || isDisabled}
                  />
                </Grid>
              )}
              {isMoving && (
                <Grid item xs={12}>
                  <SearchInput
                    onChange={handleWarehouseToChange}
                    value={warehouseIdTo}
                    options={warehousesTo.map((w) => ({ label: w.name, value: w.id }))}
                    label={errors?.warehouse_id_to?.message ?? 'На склад'}
                    placeholder="На склад"
                    error={!!errors?.warehouse_id_to?.message}
                    disabled={type === 'edit'}
                  />
                </Grid>
              )}
              <Grid item xs={12} order={isMoving ? 3 : undefined}>
                <Textarea
                  control={control}
                  label={errors?.comment?.message ?? 'Комментарий'}
                  placeholder="Комментарий"
                  name="comment"
                  error={!!errors?.comment?.message}
                  rows={isMoving ? 4.4 : 8}
                  disabled={type === 'edit' || isDisabled}
                />
              </Grid>
            </Grid>
            {type === 'create' && (
              <>
                <Grid item xs={12} sx={{ mt: '-8px' }}>
                  <Typography sx={{ fontWeight: 400, fontSize: 12, lineHeight: '16px' }} component="span" color="secondary.main">
                    {operationText.helpText}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mt: '16px' }}>
                  <OperationSearch
                    onSelect={handleAddProduct}
                    onFocus={handleFocus}
                    options={products.map((p) => ({ label: p.name, value: p }))}
                    renderOption={(product) => <ProductInfo product={product} isOption isShowTooltip={false} />}
                    disabled={!!errors?.warehouse_id_from?.message || !!errors?.warehouse_id_to?.message}
                  />
                  <Button
                    onClick={handleFormSave}
                    href={operationText.buttonHref}
                    color="primary"
                    sx={{ mt: '16px' }}
                    startIcon={<PlusIcon />}
                  >
                    {operationText.buttonText}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </form>
    </div>
  )
}
